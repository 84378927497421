import * as React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import SEO from '../components/seo'

const Page = () => {
    return (
        <Layout>
            <SEO title="Rendi produttiva la tua attività lavorativa con il CRM marketing & customer satisfaction - Thelgo" description="Grazie a Thelgo le aziende possono usufruire di un crm semplice ed intuitivo per contattare telefonicamente i clienti, per campagne di marketing, sondaggi" />
            <section className="px-6 py-12">
                <div className="container mx-auto">
                    <div className="flex gap-12 flex-wrap items-center">
                    <div className="w-full lg:w-4/12">
                            <StaticImage src="../images/custome-crm.png" alt="call center thelgo" className="w-1/4 lg:w-full" placeholder="blurred"/>
                        </div>

                        <div className="flex-1">
                            <span className="tag">Scenari di utilizzo</span>
                            
                            <h1 className="text-4xl lg:text-5xl text-persian-green-500 mb-4">Rendi produttiva la tua attività lavorativa con il CRM marketing & customer satisfaction</h1>
                            <div className="prose-persian-green prose max-w-none mb-8">
                            <p>Thelgo è un CRM utile ed efficace per la gestione della propria azienda o attività lavorativa. Il software è in grado di pianificare e gestire appuntamenti o eventi, svolgere sondaggi o ricerche di mercato e fare azione di Marketing e Customer Care.
Permette di gestire il parco clienti con la possibilità di contattarli periodicamente per proporre riordini, aggiornamenti, novità o altro.</p>
                            <p>Consente di smistare in modo semplice e automatico i lead importati da database, campagne di marketing e contattarli telefonicamente per convertirli in vendite o appuntamenti.
Inoltre, pianifica in modo preciso ed efficace eventi che necessitano il reclutamento di clienti permettendo di veicolare informazioni in più fasi organizzative permettendo così di gestire l’evento in modo continuativo.</p>
                        
                            </div>
                            <Link to="/demo" className="button-lg inline-flex text-white bg-persian-green-500 hover:bg-persian-green-600 transition-colors">Richiedi una demo</Link>
                        </div>

                    </div>
                </div>
            </section> 
        </Layout>
    )
}

export default Page